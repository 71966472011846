import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { colors } from "../utils/css/themes";
import ExpandOnHover from "../components/Helpers/ExpandOnHover";
import SEO from "../components/seo";
import { TertiaryNav } from "../components/Helpers/TertiaryNav";
import { gatsbyImageIsInstalled } from "gatsby-plugin-image/dist/src/components/hooks";
import { GatsbyImage } from "gatsby-plugin-image";

const HighlightsTemplate = (props: any) => {
  const TilesContainer = styled.div`
    max-width: 970px;
    margin: 0 auto 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  `;

  const ImgCss = css`
    width: 200px;
    height: auto;
    margin: auto;
  `;

  const SponsorName = styled.div`
    font-family: Baloo;
    font-size: 24px;
    line-height: 1.08;
    letter-spacing: -1.13px;
    text-align: center;
    color: ${colors.blue};
    padding-top: 1rem;
    height: 100%;
    max-width: 240px;
    margin: 0 auto;
  `;

  const SponsorWebsite = styled.div`
  font-family: "Baloo", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif;;
    font-size: 14px;
    font-weight: bold;
    margin-top: 1rem;
    line-height: 1.43;
    letter-spacing: 0.58px;
    text-align: center;
    color: ${colors.blue};
    text-transform: uppercase;
  `;

  const Arrow = styled.span`
    color: ${colors.orange};
  `;

  const TileAnchor = styled.a`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0.5rem 0;
    width: 302px;
    display: flex;
    flex-direction: column;
    height: 302px;
    border-radius: 4px;
    justify-content: space-around;
    box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    text-decoration: none;
  `;
  const TileDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0.5rem 0;
    width: 302px;
    height: 302px;
    border-radius: 4px;
    justify-content: space-between;
    box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    text-decoration: none;
  `;

  const page = props.data?.wpPost;

  if (!page) {
    return (
      <Layout title={page?.title || "Page Not Found"}>
        <p>Could not find data for page</p>
      </Layout>
    );
  }

  const postId = page.id;
  const sponsors = page.sponsors;
  const menuItems = props.data.MainMenu.nodes;

  const getTertiaryPages = (menuItems: MenuItem[]) => {
   // Look for the menuItem for the current page in the menu and save its parentId.
    const currentPageMenuItem = menuItems.find((item: MenuItem) => {
      return item.connectedNode.node.id == postId;
    });

    if (!currentPageMenuItem) {
      console.log(
        "The current page's id could not be found in the MainMenu hiearchy structure. Cannot create tertiary nav."
      );
      return [];
    }
    // Use the parentId to find the parent menu.
    const parentMenu: MenuItem = menuItems.find((item: MenuItem) => {
      return item.id === currentPageMenuItem.parentId;
    });
    // From the list of menuItems in the parentMenu, create list of TertiaryPages
    return parentMenu.childItems.nodes.map((menuItem: MenuItem) => {
      let tertiaryPage: TertiaryPage = {
        page_title: menuItem.label,
        page_slug: menuItem.path,
      };
      return tertiaryPage;
    });
  };
  let tertiaryPages: TertiaryPage[] = getTertiaryPages(menuItems);

  function AddTile(data: any, Tile: any, i: Number) {
    let { image, website } = data;
    // TODO Data is empty for the Nonprofits page, need to investigate
    return (
      <ExpandOnHover key={i}>
        <Tile href={website || "#"}>
          <div
            style={{
              maxHeight: 195,
              maxWidth: 195,
              margin: "1rem auto 0",
            }}
          >
            {/* TODO Need to figure out the sharpImage usage here */}
            {/* {image && image.localFile && (
              <Img
                style={ImgCss}
                alt={"highlight image"}
                fixed={image.localFile.childImageSharp.fixed}
              />
            )} */}

            {image?.localFile?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage
                image={image.localFile.childImageSharp.gatsbyImageData}
                alt={""}
                style={{
                  width: 200,
                  height: "auto",
                  margin: "auto",
                  objectFit: "cover",
                }}
              ></GatsbyImage>
            )}
          </div>
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <SponsorName>{data.name}</SponsorName>
            {data.website && (
              <SponsorWebsite>
                {" "}
                Website <Arrow>&rarr;</Arrow>
              </SponsorWebsite>
            )}
          </div>
        </Tile>
      </ExpandOnHover>
    );
  }

  function GenereateTiles() {
    return sponsors.sponsors?.map((data: any, index: number) => {
      return AddTile(data, TileAnchor, index);
    });
  }

  return (
    <Layout title={page.title}>
      {tertiaryPages && (
        <TertiaryNav
          tertiaryPages={tertiaryPages}
          DefaultPage={page.title}
        ></TertiaryNav>
      )}
      <SEO title={page.title} />
      <div
        style={{ textAlign: "center" }}
        dangerouslySetInnerHTML={{ __html: page.content }}
      />
      <TilesContainer>{GenereateTiles()}</TilesContainer>
    </Layout>
  );
};
export default HighlightsTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id, nin: "resources" }) {
      ...highlightsPost
    }
    MainMenu: allWpMenuItem(
      filter: { menu: { node: { name: { eq: "MainMenu" } } } }
    ) {
      ...highlightsMenuQuery
    }
  }
`;

interface MenuItem {
  id: string;
  parentId?: string;
  childItems: { nodes: MenuItem[] };
  connectedNode?: { node: { id: string } };
  label: string;
  path: string;
}

interface TertiaryPage {
  page_title: string;
  page_slug: string;
}
