import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";
import { Link } from "gatsby";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import Caret from "../../utils/images/SideNav/carrot-down.inline.svg";
import { useUrlParse } from "../../utils/useUrlParse";

const MobileDropdown = styled.div`
  display: none;
  background-color: blue;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
  @media (max-width: ${screenSize.smallTablet}) {
    display: block;
  }
`;

const StyledDiv = styled.div`
  @media (max-width: ${screenSize.smallTablet}) {
    margin: 0 0 2rem;
  }
  position: relative;
`;

const StyledLink = styled((props: any) => <Link {...props} />)`
  width: 100%;
  text-decoration: none;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  color: ${colors.blue};
`;

const NavTitle = styled.button`
  width: 100%;
  text-decoration: none;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  height: 39px;
  border: none;
  border-radius: 4px;
  padding-left: 1rem;
  color: white;
  border-radius: 4px;
  background-color: ${colors.blue};

  &:focus,
  &:hover {
    background-color: ${colors.blue};
    color: white;
    cursor: pointer;
  }
`;

const CaretContainer = styled.div`
  display: flex;
  margin: auto 0;
  transform: ${(props: { isOpen?: boolean }) =>
    props.isOpen ? "rotate(180deg)" : "0"};
  svg {
    path {
      stroke: white;
    }
  }
`;

const NavDropdowns = styled.div`
  width: 100%;
  display: none;
  margin: 0 0 2rem;
  position: absolute;

  @media (max-width: ${screenSize.smallTablet}) {
    display: ${(props: { isMobileNav?: boolean }) =>
      props.isMobileNav ? "block" : "none"};
  }
  @media (min-width: ${screenSize.smallTablet}) {
    display: flex;
    position: relative;
    justify-content: center;
  }
  z-index: 4;
`;

const LinkContainer = styled.div`
  a {
    color: ${(props: { currentPage?: boolean }) =>
      props.currentPage ? "black" : colors.blue};
    text-decoration: ${(props: { currentPage?: boolean }) =>
      props.currentPage ? "none" : "underline"};
  }

  @media (max-width: ${screenSize.smallTablet}) {
    border-bottom: solid 1px ${colors.blue};
    border-left: solid 1px ${colors.blue};
    border-right: solid 1px ${colors.blue};
    width: 100%;
    border-radius: 4px;
    text-decoration: none;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    height: 39px;
    border-radius: 0;
    background-color: white;
    a {
      color: ${colors.blue};
      text-decoration: none;
    }
  }
`;

export const TertiaryNav = (props: TertiaryNavProps) => {
  const [isMobileNav, setIsMobileNav] = useState(false);

  if (!props.tertiaryPages) {
    return null;
  }

  return (
    <StyledDiv>
      <MobileDropdown
        onClick={() => setIsMobileNav(!isMobileNav)}
        isMobileNav={isMobileNav}
      >
        <NavTitle isMobileNav={isMobileNav}>
          {props.DefaultPage}
          <CaretContainer isOpen={isMobileNav}>
            <Caret></Caret>
          </CaretContainer>
        </NavTitle>
      </MobileDropdown>
      <NavDropdowns isMobileNav={isMobileNav}>
        {props.tertiaryPages?.map((box: any, i: number) => (
          <LinkContainer
            currentPage={box.page_title === props.DefaultPage}
            key={i}
          >
            <StyledLink to={box.page_slug}>{box.page_title}</StyledLink>
          </LinkContainer>
        ))}
      </NavDropdowns>
    </StyledDiv>
  );
};

export interface TertiaryNavProps {
  tertiaryPages: Array<TertiaryPage>;
  DefaultPage: string;
}

interface TertiaryPage {
  page_title: string;
  page_slug: string;
}
