import * as React from "react";
import { checkPropTypes } from "prop-types";
import styled from "@emotion/styled";

const Expanding = styled.div`
  transform: scale(1);
  &:hover {
    cursor: pointer;
    transform: scale(1.025);
  }
  transition: transform 0.25s ease-in;
`;
const ExpandOnHover = (props: any) => <Expanding>{props.children}</Expanding>;

export default ExpandOnHover;
